import type {
  GeocodeResult as GoogleGeocodeResult,
  LatLngLiteral,
  OpeningHours,
} from "@googlemaps/google-maps-services-js"

import {
  GOOGLE_MAP_CITY_TYPES,
  GOOGLE_MAP_COUNTRY_TYPES,
  GOOGLE_MAP_PLACE_TYPES,
  GOOGLE_MAP_STREET_TYPES,
} from "./constants"
import type { Coordinates, GeocodingResult } from "./types"
import { GeocodingScope } from "./types"

export const getGeocodingResultScope = (res: google.maps.GeocoderResult | GoogleGeocodeResult): GeocodingScope => {
  const isPlace = res.types.some(type => GOOGLE_MAP_PLACE_TYPES.includes(type))
  if (isPlace) {
    return GeocodingScope.Place
  }

  const isStreet = res.types.some(type => GOOGLE_MAP_STREET_TYPES.includes(type))
  if (isStreet) {
    return GeocodingScope.Street
  }

  const isCity = res.types.some(type => GOOGLE_MAP_CITY_TYPES.includes(type))
  if (isCity) {
    return GeocodingScope.City
  }

  const isCountry = res.types.some(type => GOOGLE_MAP_COUNTRY_TYPES.includes(type))
  if (isCountry) {
    return GeocodingScope.Country
  }

  return GeocodingScope.Other
}

export const getFromAddressComponent = (
  { address_components }: google.maps.GeocoderResult | GoogleGeocodeResult,
  areaTypes: typeof GOOGLE_MAP_CITY_TYPES | typeof GOOGLE_MAP_COUNTRY_TYPES,
) => {
  const component = address_components.find(({ types }) => {
    if (!Array.isArray(types)) return false

    return types.some(t => areaTypes.includes(t))
  })

  return component ? component.long_name : ""
}

// Get coordinates from geocoding result for native and web
export const parsePlaceCoordinates = (coordinates: LatLngLiteral | google.maps.LatLng): Coordinates => {
  return {
    latitude: typeof coordinates.lat === "function" ? coordinates.lat() : coordinates.lat,
    longitude: typeof coordinates.lng === "function" ? coordinates.lng() : coordinates.lng,
  }
}

// Map the geocode result
export const parseGeocodeResult = (result: google.maps.GeocoderResult | GoogleGeocodeResult): GeocodingResult => {
  return {
    query: result.formatted_address,
    city: getFromAddressComponent(result, GOOGLE_MAP_CITY_TYPES),
    country: getFromAddressComponent(result, GOOGLE_MAP_COUNTRY_TYPES),
    scope: getGeocodingResultScope(result),
    coordinates: parsePlaceCoordinates(result.geometry.location),
    zip: result.address_components.find(({ types }) => types.includes("postal_code"))?.long_name || "",
    address: result.formatted_address.split(",")[0]?.trim() ?? result.formatted_address,
    viewport: [
      parsePlaceCoordinates(
        typeof (result as google.maps.GeocoderResult).geometry.viewport.getNorthEast === "function"
          ? (result as google.maps.GeocoderResult).geometry.viewport.getNorthEast()
          : (result as GoogleGeocodeResult).geometry.viewport.northeast,
      ),
      parsePlaceCoordinates(
        typeof (result as google.maps.GeocoderResult).geometry.viewport.getSouthWest === "function"
          ? (result as google.maps.GeocoderResult).geometry.viewport.getSouthWest()
          : (result as GoogleGeocodeResult).geometry.viewport.southwest,
      ),
    ],
    type: result.types[0],
    types: result.types,
  }
}

export const convertOpeningHours = (openingHoursObject?: google.maps.places.PlaceOpeningHours | OpeningHours) => {
  if (!openingHoursObject?.periods) return []
  return openingHoursObject.periods.map(period => {
    const [openHours, openMinutes, closeHours, closeMinutes] =
      (period.open.time + (period.close?.time ?? "")).match(/\d{2}/g) ?? []
    const openTime = `${openHours ?? "00"}:${openMinutes ?? "00"}:00`
    const closeTime = `${closeHours ?? "00"}:${closeMinutes ?? "00"}:00`

    const dayOfWeek = (period.open.day + 6) % 7

    return {
      dayOfWeek,
      openTime,
      closeTime,
    }
  })
}

export const getAddress = (addressComponents?: google.maps.places.PlaceResult["address_components"]) => {
  if (!addressComponents) return ""
  const route = addressComponents.find(c => c.types.includes("route"))?.long_name ?? ""
  const streetNumber = addressComponents.find(c => c.types.includes("street_number"))?.long_name ?? ""
  const unitNumber = addressComponents.find(c => c.types.includes("subpremise"))?.long_name ?? ""

  if (!unitNumber) return `${route} ${streetNumber}`

  return `${route} ${streetNumber}, ${unitNumber}`
}

export const getZip = (addressComponents?: google.maps.places.PlaceResult["address_components"]) =>
  addressComponents?.find(c => c.types.includes("postal_code"))?.long_name ?? ""

export const getTextSearchResults = (
  service: google.maps.places.PlacesService,
  request: google.maps.places.TextSearchRequest,
) => {
  return new Promise<google.maps.places.PlaceResult[]>(resolve => {
    service.textSearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(results ?? [])
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([])
      }
    })
  })
}
